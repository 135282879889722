import React from "react";
import { useI18next } from "gatsby-plugin-react-i18next";
import MarkdownRenderer from "../components/MarkdownRenderer";

const Privacy = () => {
    const { language } = useI18next();
    const markDownFileName = language === "en" ? "privacy_en.md" : "privacy.md";
    
    return (
        <div className="markdown">
            <MarkdownRenderer markdownFileName={markDownFileName} />
        </div>
    );    
}

export default Privacy;